import React from "react";
import Ranger from "./home/Ranger";
import "./Header.css";
import "./home/Navbar.css";
class Header extends React.Component {
  state = {
    isclicked: false,
  };
  sel = () => {
    this.setState({
      isclicked: !this.state.isclicked,
    });
  };
  render() {
    return (
      <div className="header">
        {this.state.isclicked && (
          <div>
            <Ranger />
          </div>
        )}
        <span>
          <h2>ZaaZoom</h2>
          <button onClick={this.sel}>
            <div className="header__text">Start your search</div>
            <div className="mobile__text">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1200 1200"
                enableBackground="new 0 0 1200 1200"
                width="20px"
              >
                <path
                  fill="#575756"
                  d="M1122.974,955.368L845.95,710.193c-6.335-5.686-13.323-9.829-20.635-13.244
c35.908-61.397,56.464-132.764,56.464-209.047c0-229.64-186.199-415.815-415.818-415.815C236.237,72.088,50,258.262,50,487.902
c0,229.723,186.237,415.923,415.961,415.923c82.821,0,160.078-24.374,224.989-66.23c4.384,8.652,10.074,16.754,17.79,23.638
l277.024,245.159c33.795,30.746,86.112,28.271,116.821-5.649l25.996-28.513C1159.207,1038.394,1156.686,986.156,1122.974,955.368z
 M465.961,742.727c-140.746,0-254.863-114.1-254.863-254.825c0-140.704,114.117-254.742,254.863-254.742
c140.583,0,254.721,114.038,254.721,254.742C720.682,628.627,606.543,742.727,465.961,742.727z"
                  style={{ fill: "rgb(255, 56, 92)" }}
                ></path>
              </svg>
              <i>Start your search</i>
            </div>
          </button>
          <div className="header__icon">
            <h3> Host your home</h3>
            <svg
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="presentation"
              focusable="false"
              style={{
                display: "block",
                height: "24px",
                width: "24px",
                fill: "currentcolor",
              }}
            >
              <path d="m16 .7c-8.437 0-15.3 6.863-15.3 15.3s6.863 15.3 15.3 15.3 15.3-6.863 15.3-15.3-6.863-15.3-15.3-15.3zm0 28c-4.021 0-7.605-1.884-9.933-4.81a12.425 12.425 0 0 1 6.451-4.4 6.507 6.507 0 0 1 -3.018-5.49c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5a6.513 6.513 0 0 1 -3.019 5.491 12.42 12.42 0 0 1 6.452 4.4c-2.328 2.925-5.912 4.809-9.933 4.809z"></path>
            </svg>
          </div>
          <div className="mobile__icon">
            <svg
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="presentation"
              focusable="false"
              style={{
                display: "block",
                height: "24px",
                width: "24px",
                fill: "currentcolor",
              }}
            >
              <path d="m16 1c8.2842712 0 15 6.71572875 15 15 0 8.2842712-6.7157288 15-15 15-8.28427125 0-15-6.7157288-15-15 0-8.28427125 6.71572875-15 15-15zm0 8c-2.7614237 0-5 2.2385763-5 5 0 2.0143973 1.2022141 3.7998876 2.9996346 4.5835001l.0003231 2.0984999-.1499943.0278452c-2.8326474.5613112-5.31897338 2.2230336-6.93575953 4.5872979 2.34343054 2.291067 5.54974273 3.7028569 9.08579613 3.7028569 3.5355506 0 6.7414538-1.4113884 9.0850203-3.701476-1.6141801-2.3628535-4.0978119-4.0247647-6.929184-4.5867938l-.1558786-.0287302.001228-2.0991413c1.7288399-.7547474 2.9066959-2.4357565 2.9936498-4.355479l.0051645-.2283797c0-2.7614237-2.2385763-5-5-5zm0-6c-7.17970175 0-13 5.82029825-13 13 0 2.9045768.95257276 5.5866683 2.56235849 7.7509147 1.42074739-1.9134907 3.33951478-3.4002416 5.53860831-4.2955956l.3480332-.1363191-.0229565-.0189706c-1.43704227-1.2411241-2.34462949-3.045583-2.42083359-5.0285539l-.00520991-.2714755c0-3.8659932 3.1340068-7 7-7s7 3.1340068 7 7c0 1.9941317-.8415062 3.8279876-2.224566 5.1193683l-.225434.2006317.0447787.0163138c2.3268368.8792152 4.3570558 2.4138611 5.8430586 4.4127726 1.6098837-2.1632453 2.5621627-4.8449575 2.5621627-7.7490864 0-7.17970175-5.8202983-13-13-13z"></path>
            </svg>
          </div>
        </span>
      </div>
    );
  }
}
export default Header;
